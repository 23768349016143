// Docs: https://www.contentful.com/developers/docs/references/images-api/

import type { ContentfulImagesAPI } from '@lib/types'
import type { ImageLoaderProps } from 'next/image'

/**
 * Factory function for NextJS <Image> element loader
 *
 * @param outerLoaderProps a set of parameters that will be passed to an image loader: width, height, and quality, among others; end result is the url string for the image
 * @param heightMap is an array of { width, height } combinations that ultimately determine the height from a given width range
 * @param heightScaler is a number input that essentially shrinks or enlarges the image
 * @returns a function that returns an href string for the image
 */

// if this is a hero image, then the loader needs to account for heights.
// the loader will iterate through a series of widths and this function will have
// to match those widths to a particular height

function calculateMatchedHeight(heightMap: any, width: number, heightScaler?: number) {
  if (heightMap?.length) {
    return heightMap.reduce((acc: any, cur: { width: number; height: number }) => {
      if (width && width >= cur.width) {
        return heightScaler ? Math.round(heightScaler * cur.height) : cur.height
      }
      return acc
    }, heightMap[0].height) // Default to the first height if no match
  } else return null
}

type ContentfulLoaderProps = Omit<Partial<ImageLoaderProps>, 'quality'>
export default function createContentfulLoader(
  outerLoaderProps: ContentfulImagesAPI,
  heightMap?: { width: number; height: number }[],
  heightScaler?: number
) {
  // src and width get passed in from the <Image> element props
  return ({ src, width }: ContentfulLoaderProps) => {
    const validUrlString = 'https:' + src
    const url = new URL(validUrlString)

    // A matched height is determined by how the passed in width matches up within a given range
    // in the heightMap
    const matchedHeight = width && calculateMatchedHeight(heightMap, width, heightScaler)

    if (width) {
      url.searchParams.set('w', width.toString())
    }
    if (matchedHeight) {
      url.searchParams.set('h', matchedHeight.toString())
    }

    Object.entries(outerLoaderProps).forEach(([key, val]) => {
      switch (key) {
        case 'r':
          url.searchParams.set(key, val.toString())
          break
        case 'q':
          url.searchParams.set('q', val.toString() || '75')
          break
        default:
          if (key && val) {
            url.searchParams.set(key, val)
          }
          break
      }
    })
    return url.href
  }
}
