'use client'
import { blockQueryParamMap } from '@lib/constants'
import { useEffect } from 'react'

const useCustomScroll = () => {
  useEffect(() => {
    // 👇️ only runs once onload
    window.scrollOffSet = customScrollTo(window.location.href)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function customScrollTo(href: string): number {
    let scrollOffSet = 0

    // if we have a header lets offset the height
    if (typeof window !== 'undefined') {
      const headers = document.getElementsByTagName('header')
      if (headers.length) {
        scrollOffSet += headers[0].offsetHeight
      }
    }

    // if we have a navbar lets offset the height
    if (typeof window !== 'undefined') {
      const navBars = document.querySelectorAll("[data-block='navBar']")
      if (navBars.length) {
        scrollOffSet += (navBars[0] as HTMLElement).offsetHeight
      }
    }

    const sectionIndex = href.indexOf('#')
    // if we have a section, scroll to there
    if (sectionIndex != -1) {
      const elementId = href.substring(sectionIndex + 1)
      const element = document.getElementById(elementId)
      if (element !== null) {
        doScroll(element.getBoundingClientRect().top + window.scrollY - scrollOffSet)
        // Lets return the offset to be used elsewhere
        return scrollOffSet
      }
    }

    // if we have a paginated block scroll to there
    const url: URL = new URL(href)
    const params: URLSearchParams = url.searchParams

    // Loop over paginated blocks
    for (const block in blockQueryParamMap) {
      const hasBlock = params.get(blockQueryParamMap[block])
      if (hasBlock !== null) {
        // Get the blocks associated with query param
        const elements = document.querySelectorAll(`[data-block='${block}']`)
        if (elements.length) {
          // Scroll to first element
          doScroll(elements[0].getBoundingClientRect().top + window.scrollY - scrollOffSet)
          // Lets return the offset to be used elsewhere
          return scrollOffSet
        }
      }
    }

    // Lets return the offset to be used elsewhere
    return scrollOffSet
  }
}

export const doScroll = (top: number) => {
  window.scrollTo({
    top,
    left: 0,
    behavior: 'smooth',
  })
}

export default useCustomScroll
