import { add, isSameDay, isValid } from 'date-fns'
import { formatInTimeZone, getTimezoneOffset } from 'date-fns-tz'

const pacificTimeZone = 'America/Los_Angeles'

// WEEKDAY - full
// MONTH - short
// TODAY/TOMORROW - yes
//  e.g.
//   Today, Jan. 1
//   Tomorrow, Jan. 2
//   Friday, Jan. 3
// (previously dateLongShortNum)
export const dateDefault = (date: Date | string) => {
  if (!isValid(new Date(date))) {
    return '[Invalid date]'
  }

  const pacific = getPacificDateParts(date)
  let result = ''
  result += `${pacific.relativeWeekday || pacific.weekday}, `
  result += `${pacific.monthShort} `
  result += `${pacific.day}`
  return result
}

// WEEKDAY - none
// MONTH - short
// TODAY/TOMORROW - yes
//  e.g.
//   Today
//   Tomorrow
//   Friday, Jan. 3
// (previously dateNoShortNum)
export const dateFirstAvailable = (date: Date | string) => {
  if (!isValid(new Date(date))) {
    return '[Invalid date]'
  }

  const pacific = getPacificDateParts(date)
  let result = ''
  if (pacific.relativeWeekday.length > 0) {
    // Today, Tomorrow
    result += `${pacific.relativeWeekday}`
  } else {
    // Friday, Jan. 3
    result += `${pacific.weekday}, `
    result += `${pacific.monthShort} `
    result += `${pacific.day}`
  }
  return result
}

export const dateFirstAvailableFadPromo = (date: Date | string) => {
  if (!isValid(new Date(date))) {
    return '[Invalid date]'
  }

  const pacific = getPacificDateParts(date)
  let result = ''
  if (pacific.relativeWeekday.length > 0) {
    // Today, Tomorrow
    result += `${pacific.relativeWeekday}`
  } else {
    // Jan. 3
    result += `${pacific.monthShort} `
    result += `${pacific.day}`
  }
  return result
}

// WEEKDAY - full
// MONTH - short
// TODAY/TOMORROW - no
export const dateTimePickerHeader = (date: Date | string) => {
  if (!isValid(new Date(date))) {
    return '[Invalid date]'
  }

  const pacific = getPacificDateParts(date)
  let result = ''
  result += `${pacific.weekday}, `
  result += `${pacific.monthShort} `
  result += `${pacific.day}`
  return result
}

// format the datetime for locations
// if today or tomorrow
//   8:00 am
// else
//   Monday, Jan. 1
export const saveAspotSummary = (datetime: number | string | Date) => {
  const dateConv = new Date(datetime)
  if (!isValid(new Date(datetime))) {
    return ''
  }

  const pacific = getPacificDateParts(dateConv)
  let result = ''
  if (pacific.relativeWeekday.length > 0) {
    result += `${pacific.relativeWeekday?.toLowerCase() || pacific.weekday} `
    result += `${pacific.hour}:${pacific.minute} ${pacific.ampm}`
  } else {
    result += `${pacific.relativeWeekday?.toLowerCase() || pacific.weekday}, `
    result += `${pacific.monthShort || pacific.weekday} ${pacific.day}`
  }
  return result
}

// if today or tomorrow
//   8:00 am
// else
//   Monday, 8:30 pm
export const hoursSummary = (datetime: string | Date) => {
  if (!isValid(new Date(datetime))) {
    return ''
  }

  const pacific = getPacificDateParts(datetime)
  let result = ''

  if (pacific.relativeWeekday.length > 0) {
    result += `${pacific.hour}:${pacific.minute} ${pacific.ampm}`
  } else {
    result += `${pacific.weekday} ${pacific.hour}:${pacific.minute} ${pacific.ampm}`
  }
  return result
}

// Format Time h:mm a
export const formatTime = (datetime: string | Date) => {
  if (!isValid(new Date(datetime))) {
    return ''
  }
  const pacific = getPacificDateParts(datetime)
  let result = ''
  result += `${pacific.hour}:${pacific.minute} ${pacific.ampm}`
  return result
}

// Abbreviate months with six or more letters, include period
// Associated Press style
export const shortMonth = (month: string): string => {
  switch (month) {
    case 'Jan':
    case 'January':
      return 'Jan.'
    case 'Feb':
    case 'February':
      return 'Feb.'
    case 'Mar':
    case 'March':
      return 'March'
    case 'Apr':
    case 'April':
      return 'April'
    case 'May':
      return 'May'
    case 'Jun':
    case 'June':
      return 'June'
    case 'Jul':
    case 'July':
      return 'July'
    case 'Aug':
    case 'August':
      return 'Aug.'
    case 'Sep':
    case 'Sept':
    case 'September':
      return 'Sept.'
    case 'Oct':
    case 'October':
      return 'Oct.'
    case 'Nov':
    case 'November':
      return 'Nov.'
    case 'Dec':
    case 'December':
      return 'Dec.'
    default:
      return 'Invalid Month'
  }
}

const shortDay = (weekday: string) => {
  return weekday.slice(0, 3)
}

// used to convert yyyy-MM-dd to midnight PACIFIC time Date
export const pacificMidnight = (date: string): string => {
  const utcOffset = getTimezoneOffset(pacificTimeZone, new Date(date)) / 60 / 60 / 1000
  const hour = (24 + utcOffset).toFixed(0).padStart(2, '0')
  return `${date}T${hour}:00:00.000Z`
}

/**
 * receives any date and returns the PACIFIC date parts in an object to be formatted however one would like
 *
 * @param {Date|string} date - The date object or string representation of a date.
 * @returns {object} - An object containing the date parts in the Pacific time zone.
 *                    The properties include: weekday, month, day, hour, minute, seconds, ampm, utcOffset.
 */
export const getPacificDateParts = (date: Date | string) => {
  if (typeof date === 'string' && date.length === 10) {
    // SOMEBODY sent '2023-07-19' as a Date, make sure we convert it to midnight PACIFIC time
    date = pacificMidnight(date)
  }

  //  move the new function here
  const pacificDate = formatInTimeZone(
    new Date(date),
    pacificTimeZone,
    'EEEE MMMM d h mm ss aaa XXX'
  )
  const [weekday, month, day, hour, minute, seconds, ampm, utcOffset] = pacificDate
    .split(' ')
    .map((part) => part.trim())

  const relativeWeekday = getRelativeWeekDay(new Date(date))
  const monthShort = shortMonth(month)
  const weekdayShort = shortDay(weekday)

  return {
    relativeWeekday,
    weekday,
    weekdayShort,
    month,
    monthShort,
    day,
    hour,
    minute,
    seconds,
    ampm,
    utcOffset,
  }
}

export const getRelativeWeekDay = (date: Date): string => {
  if (isTodayTz(date)) {
    return 'Today'
  } else if (isTomorrowTz(date)) {
    return 'Tomorrow'
  } else {
    return ''
  }
}

/**
 * Checks if two dates are in the same day in a specific time zone.
 * "today" is different based on your local time zone
 * using date-fns.isToday() uses the local/machine time zone to check such things
 * this forces a check within a specific time zone
 *
 * @param {Date} date1 - The first date.
 * @param {Date} date2 - The second date.
 * @param {string} [destinationTimeZone='America/Los_Angeles'] - The destination time zone
 * @returns {boolean} Returns true if the two dates are in the same day in the specified time zone, otherwise false.
 */
export const isSameDayTz = (
  date1: Date,
  date2: Date,
  destinationTimeZone: string = pacificTimeZone
): boolean => {
  const tzDate1 = getOffsetLocalDate(date1, destinationTimeZone)
  const tzDate2 = getOffsetLocalDate(date2, destinationTimeZone)

  return isSameDay(tzDate1, tzDate2)
}

/**
 * Checks if a date is today in a specific time zone.
 *
 * @param {Date} date1 - The date to compare with today.
 * @param {string} [destinationTimeZone='America/Los_Angeles'] - The destination time zone
 * @returns {boolean} Returns true if the date is today in the specified time zone, otherwise false.
 */
export const isTodayTz = (date1: Date, destinationTimeZone: string = pacificTimeZone) => {
  return isSameDayTz(date1, new Date(), destinationTimeZone)
}

/**
 * Checks if a date is tomorrow in a specific time zone.
 *
 * @param {Date} date1 - The date to compare with tomorrow.
 * @param {string} [destinationTimeZone='America/Los_Angeles'] - The destination time zone.
 * @returns {boolean} Returns true if the date is tomorrow in the specified time zone, otherwise false.
 */
export const isTomorrowTz = (date1: Date, destinationTimeZone: string = pacificTimeZone) => {
  const tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)
  return isSameDayTz(date1, tomorrow, destinationTimeZone)
}

/**
 * Converts a UTC date string to a date object in the local time zone,
 * accounting for the desired time zone offset.
 *
 * @param {date} utcDate - The UTC date string to convert.
 * @param {string} desiredTimeZone - The desired time zone (default: 'America/Los_Angeles').
 * @returns {Date} - The converted local time zone date.
 */
export const getOffsetLocalDate = (
  utcDate: Date,
  desiredTimeZone: string = pacificTimeZone
): Date => {
  // eslint-disable-next-line sonarjs/new-cap
  const localTimeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone

  // Calculate the time zone offset difference between the desired time zone and the local time zone
  const desiredTimeZoneOffset = getTimezoneOffset(desiredTimeZone, utcDate)
  const localTimeZoneOffset = getTimezoneOffset(localTimeZone, utcDate)
  const offsetDifferenceMinutes = (desiredTimeZoneOffset - localTimeZoneOffset) / 60 / 1000 || 0

  // Add the offset difference to the UTC date
  return add(utcDate, {
    minutes: offsetDifferenceMinutes,
  })
}

const methods = {
  dateDefault,
  dateFirstAvailable,
  dateFirstAvailableFadPromo,
  dateTimePickerHeader,
  formatTime,
  getOffsetLocalDate,
  getPacificDateParts,
  getRelativeWeekDay,
  hoursSummary,
  isSameDayTz,
  isTodayTz,
  isTomorrowTz,
  saveAspotSummary,
  shortDay,
  shortMonth,
}
export default methods
