'use client'

import { createContext, useContext, useEffect, type ReactNode } from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { config } from '@fortawesome/fontawesome-svg-core'
import useCustomScroll from '@hooks/useCustomScroll'
import { type AppConfigType } from '@lib/services/aws-app-config-service'
import aa from 'search-insights' // Algolia Analytics

// AWS App config (+featureflags) for global usage via useContext
export const AppConfig = createContext<AppConfigType | undefined>(undefined)

export const useAppConfig = () => {
  const ctx = useContext(AppConfig)
  if (ctx === undefined) {
    throw new Error('useAppConfig must have a higher order ClientWrapper provider')
  }
  return ctx
}

// Prevent font awesome from creating a style element; instead
// we add it via PostCSS in styles/main.css
config.autoAddCss = false

export interface ClientWrapperProps {
  readonly children: ReactNode
  readonly appConfig: AppConfigType
  readonly userToken?: string
}

export default function ClientWrapper({ children, appConfig, userToken }: ClientWrapperProps) {
  useCustomScroll()

  useEffect(() => {
    aa('init', {
      appId: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
      apiKey: appConfig.configs.algolia.algoliaKey,
      userToken,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITEKEY ?? ''}
      scriptProps={{
        async: true,
      }}>
      <AppConfig.Provider value={appConfig}>{children}</AppConfig.Provider>
    </GoogleReCaptchaProvider>
  )
}
