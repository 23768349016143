import { type GeoData, parseGeocodeResult } from '@lib/utilities/location-utilities'
import { useJsApiLoader } from '@react-google-maps/api'

/**
 * reverseGeocode is an asynchronous function that performs reverse geocoding using the Google Maps Geocoding API.
 *
 * @async
 * @function
 * @example
 * // Perform reverse geocoding
 * const locationResults = await reverseGeocode({ lat: 37.7749, lng: -122.4194 });
 *
 * @param {object} location - The location object with latitude and longitude.
 * @param {number} location.lat - The latitude.
 * @param {number} location.lng - The longitude.
 * @returns {Promise<Array<GeoData>>} A promise that resolves to an array of parsed GeoData objects.
 * @throws {Error} If there is an error during reverse geocoding.
 */
const reverseGeocode = async (location: { lat: number; lng: number }): Promise<GeoData[]> => {
  const googleMapsGeocoder = new google.maps.Geocoder()
  try {
    const { results } = await googleMapsGeocoder.geocode({ location })
    if (results.length > 0) {
      const parsedResults: GeoData[] = results.map((result) => parseGeocodeResult(result))
      return parsedResults
    } else {
      // console.warn('No results found for the given location')
      return []
    }
  } catch (error: any) {
    // console.error('Reverse geocoding error:', error.message)
    throw error
  }
}

/**
 * geocode is an asynchronous function that performs geocoding using the Google Maps Geocoding API.
 *
 * @async
 * @function
 * @example
 * // Perform geocoding
 * const addressResults = await geocode('San Francisco, CA');
 *
 * @param {string} address - The address to be geocoded.
 * @returns {Promise<Array<GeoData>>} A promise that resolves to an array of parsed GeoData objects.
 * @throws {Error} If there is an error during geocoding.
 */
const geocode = async (address: string): Promise<GeoData[]> => {
  const googleGeocoder = new google.maps.Geocoder()
  // Specify geocode request with component restrictions
  const geocodeRequest = {
    address,
    componentRestrictions: {
      country: 'US',
      administrativeArea: 'CA',
    },
  }

  try {
    const { results } = await googleGeocoder.geocode(geocodeRequest)
    if (results.length > 0) {
      const parsedResults: GeoData[] = results.map((result) => parseGeocodeResult(result))
      return parsedResults
    } else {
      // console.warn('No results found for the given address')
      return []
    }
  } catch (error: any) {
    // console.error('Geocoding error:', error.message)
    throw error
  }
}

const fetchLocations = async (
  locationQuery: string
): Promise<{ locations: Array<GeoData>; message: string }> => {
  try {
    // call Google Maps Geocoding API
    const geocodeResults = await geocode(locationQuery)
    // filter out locations without cites e.g. "CA" or "United States"
    const resultsWithCities = geocodeResults.filter((result) => result.city)

    return { locations: resultsWithCities, message: '' } // Return an object with locations and an empty message
  } catch (error) {
    let friendlyError = ''
    switch ((error as any)?.code) {
      case google.maps.GeocoderStatus.OVER_QUERY_LIMIT:
        friendlyError = 'Location search was too busy, please try again.'
        break
      case google.maps.GeocoderStatus.REQUEST_DENIED:
        friendlyError = 'Location search was not allowed.'
        break
      case google.maps.GeocoderStatus.UNKNOWN_ERROR:
        friendlyError = 'Location search failed, please try again.'
        break
      case google.maps.GeocoderStatus.ZERO_RESULTS:
        friendlyError = 'Location could not be found, please revise and try again.'
        break
      case google.maps.GeocoderStatus.ERROR:
      default:
        friendlyError = 'There was an error finding the location, please try again.'
        break
    }
    return { locations: [], message: friendlyError } // Return an object with an empty locations array and the error message
  }
}

const useGeocode = () => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY ?? '',
  })

  return {
    fetchLocations,
    geocode,
    reverseGeocode,
    isLoaded,
  }
}

export { geocode, reverseGeocode, useGeocode }
export default useGeocode
