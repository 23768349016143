import type { ComponentPropsWithoutRef } from 'react'
import React from 'react'
import type { TypeDisplaySchedule } from '@lib/location-types'

export interface LocationHoursDayProps extends ComponentPropsWithoutRef<'div'> {
  displayReadySchedule: TypeDisplaySchedule[]
}

const HoursText = ({ scheduleDay }: { scheduleDay: TypeDisplaySchedule }) => {
  let content

  switch (scheduleDay.type) {
    case 'Open':
      content = `${scheduleDay.hours[0][0]} - ${scheduleDay.hours[0][1]}`
      break
    case 'Closed':
      content = 'Closed'
      break
    case 'Split':
      content = scheduleDay.hours.map((slot, index) => (
        <React.Fragment key={index}>
          {`${slot[0]} - ${slot[1]}`}
          {index !== scheduleDay.hours.length - 1 && (
            <>
              <span>, </span>
              <br />
            </>
          )}
        </React.Fragment>
      ))
      break
    case '24 Hours':
      content = 'Open 24 hours'
      break
    default:
      content = ''
  }

  return <div className="empty:hidden">{content}</div>
}

const LocationHoursDisplay = ({ displayReadySchedule, ...props }: LocationHoursDayProps) => {
  if (!Array.isArray(displayReadySchedule) || displayReadySchedule.length === 0) {
    return null
  }

  return (
    <table className="empty:hidden max-w-[483px]" {...props}>
      <caption className="sr-only">Location Hours</caption>
      <tbody>
        {displayReadySchedule.map((scheduleDay) => (
          <tr key={scheduleDay.dayText} className="text-left">
            <th scope="row" className="align-top font-medium">
              {scheduleDay.dayText}
            </th>
            <td>
              <HoursText scheduleDay={scheduleDay} />
              {/* Filter out a schedule day message with soft closure status */}
              {scheduleDay.isException &&
                scheduleDay.exceptionMessage?.toLowerCase() !== 'soft close' && (
                  <div className="col-span-2 text-base font-semibold sm:whitespace-nowrap">
                    {scheduleDay.exceptionMessage}
                  </div>
                )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default LocationHoursDisplay
