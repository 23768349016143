import { type ComponentPropsWithoutRef, useMemo } from 'react'
import LocationHoursSummary from '@app/[locale]/(main)/locations/_components/location-hours-summary'
import LocationHoursDisplay from '@app/[locale]/(main)/locations/_components/location-hours-display'
import {
  convertToDisplayReadySchedule,
  getScheduleSummaryInfo,
} from '@lib/utilities/location-hours-utilities'
import type { TypeScheduleAmended, TypeScheduleException } from '@lib/location-types'
import clsx from 'clsx'
import { Typography } from '@shc/ui'
export interface LocationHoursProps extends ComponentPropsWithoutRef<'div'> {
  now: string
  schedule: Readonly<TypeScheduleAmended[]>
  scheduleNotes?: string | null
  scheduleExceptions?: Readonly<TypeScheduleException[]>
  summaryOnly?: boolean
}

const LocationHours = ({
  now,
  schedule,
  scheduleNotes,
  scheduleExceptions = [],
  summaryOnly = false,
  className,
  ...props
}: LocationHoursProps) => {
  const displayReadySchedule = useMemo(() => {
    return convertToDisplayReadySchedule(now, schedule, scheduleExceptions)
  }, [now, schedule, scheduleExceptions])

  const { operationalStatus, nextOpenTime, locationExceptionText } = useMemo(() => {
    return getScheduleSummaryInfo(now, displayReadySchedule)
  }, [now, displayReadySchedule])

  // Today Only
  if (summaryOnly) {
    return (
      <LocationHoursSummary
        operationalStatus={operationalStatus}
        nextOpenTime={nextOpenTime}
        locationExceptionText={locationExceptionText}
      />
    )
  }

  // Default
  return (
    <div className={clsx('flex flex-col', className)} {...props}>
      <Typography variant="h2" className="pb-3">
        Hours
      </Typography>
      <Typography variant="h5" className="pb-3">
        {Array.isArray(displayReadySchedule) && displayReadySchedule.length > 0 && (
          <LocationHoursSummary
            operationalStatus={operationalStatus}
            nextOpenTime={nextOpenTime}
            locationExceptionText={locationExceptionText}
          />
        )}
      </Typography>
      <LocationHoursDisplay displayReadySchedule={displayReadySchedule} />
      {scheduleNotes && <div className="text-base font-medium pt-3">{scheduleNotes}</div>}
    </div>
  )
}

export default LocationHours
